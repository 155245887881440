dmx.Component('bs5-theme', {
  initialData: {
    theme: 'auto',
    active: 'light',
    detected: 'light',
  },

  attributes: {
    key: {
      type: String,
      default: 'theme',
    },

    theme: {
      type: String,
      default: 'auto',
      enum: ['auto', 'light', 'dark'],
    },
  },

  methods: {
    setTheme (theme) {
      this.props.theme = theme;
      this.performUpdate();
    },
  },

  render: false,

  init () {
    this._changeHandler = this._changeHandler.bind(this);
    this._mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this._mediaQuery.addEventListener('change', this._changeHandler);
    this.props.theme = this._getStoredTheme() || this.props.theme;
    this._update();
  },

  destroy () {
    this._mediaQuery.removeEventListener('change', this._changeHandler);
  },

  performUpdate () {
    this._setStoredTheme(this.props.theme);
    this._update();
  },
  
  _update () {
    const detected = this._mediaQuery.matches ? 'dark' : 'light';
    const active = this.props.theme === 'auto' ? detected : this.props.theme;

    document.documentElement.setAttribute('data-bs-theme', active);

    this.set('theme', this.props.theme);
    this.set('active', active);
    this.set('detected', detected);
  },

  _changeHandler (event) {
    this._update();
  },

  _getStoredTheme () {
    return localStorage.getItem(this.props.key);
  },

  _setStoredTheme (theme) {
    localStorage.setItem(this.props.key, theme);
  },

});